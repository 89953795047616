class Game {
	constructor() {
		this.game = document.querySelector('#game');
		this.step = 1;
		this.nextStep = this.step + 1;
		this.nextStepElement = document.querySelector(`[data-step='${this.nextStep}']`);
		this.items = this.game.querySelectorAll('.item');
		this.started = false;
		this.ended = false;
		this.order = [
			14, 3, 11, 9, 1, 4, 12, 10, 2, 13, 6, 8, 5, 7
		];
		this.clicked = [];
		this.init();
	}

	init() {
		this.items.forEach(item => {
			item.addEventListener('click', (e) => {
				e.preventDefault();
				this.checkGame(e.currentTarget);
			})
		})
	}

	checkGame(element) {
		const index = parseInt(element.dataset.index, 10);
		const currentOrderIndex = this.clicked.length;

		// Check if the clicked element is the next in the order
		if (index === this.order[currentOrderIndex]) {
			this.clicked.push(index);
			element.classList.add('active');

			if (!this.started) {
				this.started = true;
				this.game.classList.add('started');
			}

		} else {
			// Reset the game if the wrong item is clicked
			this.resetGame();
			return;
		}

		// Check if all items are clicked in the correct order
		if (this.clicked.length === this.order.length) {
			this.ended = true;
			this.endGame();
		}
	}

	resetGame() {
		this.clicked = [];
		this.items.forEach(item => item.classList.remove('active'));
		this.game.classList.remove('started');
		this.started = false;
	}

	endGame() {
		this.game.classList.remove('show');
		this.nextStepElement.classList.add('show');
	}
}

export default Game;
