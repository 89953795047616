class Form {
	constructor() {
		this.form = document.querySelector('form#login');
		this.step = 2;
		this.nextStep = this.step + 1;
		this.nextStepElement = document.querySelector(`[data-step='${this.nextStep}']`);
		this.user = "lanternes.corail"
		this.password = "OMAN"
		this.formError = this.form.querySelector('.error');
		this.passwordInput = this.form.querySelector('#password');
		this.usernameInput = this.form.querySelector('#user');
		this.button = this.form.querySelector('button');
		this.init();
	}

	init() {
		this.form.addEventListener('submit', (e) => {
			e.preventDefault();
			e.stopPropagation();
			this.checkPassword();
		});
	}

	checkPassword() {
		if (this.passwordInput.value === this.password && this.usernameInput.value === this.user) {
			this.formError.style.display = 'none';
			this.form.classList.remove('show');
			this.nextStepElement.classList.add('show');
		} else {
			this.formError.style.display = 'block';
		}
	}
}

export default Form;